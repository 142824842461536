<template>
  <div className="pager">
    <template v-if="!disabledPageValue">
      <a href="#" className="pager-prev disabled" v-if="currentPage === 1" v-on:click="noClick"></a>
      <a href="#" className="pager-prev" v-else v-on:click="pageDown"></a>

      <div className="pager-input">
        <div className="input">
          <input type="text" ref="page" name="page" v-bind:value="currentPage"
                 v-on:keyup.enter="updatePage($event.target.value)" v-on:keyup.esc="restorePage" v-on:blur="restorePage"
                 className="page" maxLength="10">
        </div>

        <div className="pager-label">/ {{ maxPage }}</div>
      </div>

      <a href="#" className="pager-next disabled" v-if="currentPage >= maxPage" v-on:click="noClick"></a>
      <a href="#" className="pager-next" v-else v-on:click="pageUp"></a>
    </template>

    <div className="input select">
      <select name="per_page" v-model.number="limit">
        <option value="25">25</option>
        <option value="50">50</option>
        <option value="100">100</option>
        <option value="500">500</option>
      </select>
    </div>
  </div>
</template>


<script>
import $ from 'jquery';

export default {
  name: 'Pager',

  props: {
    pageDisabled: String
  },

  computed: {
    disabledPageValue() {
      return this.pageDisabled === 'true'
    }
  },

  data() {
    return {
      currentPage: 1,
      maxPage: 1,
      limit: 100
    }
  },

  created() {
    if (!this.$root.$children) {
      this.$root.$children = [this];
    } else {
      this.$root.$children.map((i, k) => {
        if (i.name === this.name) {
          this.$root.$children.splice(k, 1)
        }
      })
      this.$root.$children.push(this);
    }
  },

  watch: {
    currentPage() {
      if (this.currentPage > this.maxPage) {
        this.currentPage = this.maxPage;
      } else if (this.currentPage < 1) {
        this.currentPage = 1;
      }

      this.emitter.emit('changePager', {page: this.currentPage, limit: this.limit});
    },


    limit() {
      this.emitter.emit('changePager', {page: this.currentPage, limit: this.limit});
    }
  },

  methods: {

    noClick(e) {
      e.preventDefault();
    },

    restorePage() {
      this.$refs.page.value = this.currentPage;
    },

    updatePage(page) {
      this.currentPage = parseInt(page) || 1;
    },

    pageDown(e) {
      e.preventDefault();
      this.currentPage--;
    },

    pageUp(e) {
      e.preventDefault();
      this.currentPage++;
    }
  },

  mounted() {
    $(this.$refs.page).numeric({negative: false, decimal: false});
  }
}
</script>