<template>
  <div>
    <h1 class="tab-header">
      <span>
        Инкассация
      </span>
      <a v-on:click.prevent="now = true">Новая</a>
    </h1>

    <div>
      <Report ref="report" url="collection/report" :def-params="{order: {field: 'collection_id', order: 'desc'}, filters: {collector_id: 'eq|' + account_id}}" v-on:loading="reportLoading" v-on:done="reportLoaded">
        <div v-if="error">
          {{error}}
        </div>
        <div v-else>

          <div v-if="report" class="card-report">
            <div v-if="report.rows_count === 0">
              Не найдено.
            </div>

            <template v-else>
              <div
                  class="item"
                  v-for="(row, index) in report.rows"
                  v-bind:key="index"
              >
                <h3>
                  № {{row.collection_id}} от {{convertDate(row.collection_date)}}
                </h3>

                <div>
                  Тип: <b>{{ collectionType[row.collection_type] }}</b>
                </div>
                <div>
                  Сумма: <b>{{formatRUB(row.collection_amount)}}</b>
                </div>
                <div>
                  Кассир: <b>{{row.account_title}}</b>
                </div>



              </div>

            </template>
          </div>

          <Pager />
        </div>
      </Report>
    </div>


    <div class="window" v-if="now">
      <div class="window-header">
        <h1>
          <i class="back" v-on:click="back()"></i>
          Новая инкассация
        </h1>
      </div>
      <div class="window-form">

        <div class="steps" :data-step="collectStep" :data-title="collectStepTitle[collectStep]">
          <i></i>
          <i></i>
          <i></i>
          <i></i>
        </div>

        <template v-if="collectStep === 1">

          <button v-if="bar && bar.split('').length > 7" class="btn btn-primary mb14" v-on:click="getCollect(bar)">Продолжить</button>

        <div class="scanner-qr">
          <qrcode-stream :key="indexKey" :track="paintOutline" @detect="onDecode"></qrcode-stream>
          <button v-on:click.prevent="indexKey++" class="btn btn-primary" style="margin-top: 10px">Reload</button>
        </div>

        </template>

        <template v-if="collectStep === 2">

          <template v-if="data && data.account_id">

            <div class="input mb14">
              <label class="input-placeholder">Сумма - основная</label>
              <div style="font-size: 24px">
                <b>{{ formatRUB( data.collection_amount ) }}</b>
              </div>
            </div>

            <div class="input mb14">
              <label class="input-placeholder">Сумма - допы</label>
              <div style="font-size: 24px">
                <b>{{ formatRUB( data.collection_amount_d ) }}</b>
              </div>
            </div>

          </template>

        </template>

      </div>

      <div class="form-submit">
        <button class="btn btn-primary" v-if="collectStep === 2" v-on:click.prevent="collectStep === 2 ? save() : getCollect()">{{collectStep === 2 ? 'Информация верна' : 'Продолжить'}}</button>
        <a class="btn btn-secondary" v-on:click.prevent="data = {};now = false;collectStep = 1">Отменить</a>
      </div>
    </div>

    <Nav />

  </div>
</template>

<script>

import mixins from '../../../helpers/mixins.js';

import Nav from "@/pages/collector/nav";
import User from '../../../user';
import Ajax from "@/helpers/ajax";

import Report from '../../../components/report/Report';
import Pager from '../../../components/Pager';


import { QrcodeStream } from 'vue-qrcode-reader'


export default {
  mixins: [mixins],

  components: {
    QrcodeStream,

    Nav,
    Report,
    Pager
  },

  data() {
    return {
      indexKey: 0,
      bar: null,
      account_id: User.currentUser.account.account_id || 0,
      now: false,
      data: {
        account_id: null,
        collection_amount: null
      },


      collectStep: 1,

      collectionType: {
        basic: 'Основная',
        other: 'Допы'
      },

      collectStepTitle: [
        '',
        'Поиск заявки',
        'Информация о заявке',
      ],
      error: null,
      report: null
    }
  },

  methods: {

    back() {
      if (this.collectStep === 1) {
        this.data = false;
        this.bar = null
      } else {
        this.collectStep--
      }
    },


    async getCollect(barcode) {

      this.data = {
        account_id: barcode.a,
        collection_amount: barcode.e,
        collection_amount_d: barcode.d
      }
      this.collectStep = 2;
    },

    async save() {
      let list = []

      list.push(Ajax.post(`/collection/save/0`, this.data));

      let [orderResult] = await Promise.all(list);

      if (orderResult.error) {
        this.$toast.error({content: `Ошибка сохранения`});
      } else {
        this.$toast.success({content: `Информация сохранена`});
        this.collectStep = 1;

        this.data = {
          account_id: false,
          collection_amount: 0
        }
        this.bar = null
      }
    },

    onDecode(detectedCodes) {

           this.showCheckin(JSON.parse(detectedCodes[0].rawValue))
    },

    paintOutline(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const [firstPoint, ...otherPoints] = detectedCode.cornerPoints

        ctx.strokeStyle = 'red'

        ctx.beginPath()
        ctx.moveTo(firstPoint.x, firstPoint.y)
        for (const { x, y } of otherPoints) {
          ctx.lineTo(x, y)
        }
        ctx.lineTo(firstPoint.x, firstPoint.y)
        ctx.closePath()
        ctx.stroke()
      }
    },
    onLoaded() {
      console.log('onLoaded')
    },

    showCheckin(barcode) {
      this.collectStep = 1;
      this.getCollect(barcode)
    },

    reportLoading() {
      this.error = null;
    },

    reportLoaded(report) {
      if (report.response) {
        this.report = report.response;
        this.error = null;
      } else {
        this.report = null;
        this.error = 'Неизвестная ошибка.';
      }
    },

  }
}
</script>
<style>
  .scanner-qr {
    aspect-ratio: 1/1;
  }
</style>