<template>
  <div class="select" :class="{ 'select--empty': isEmpty, 'select--non-empty': !isEmpty, 'select--focused': isFocused, 'select--error': inputError }">

    <label class="select__label" :class="{ 'select__label--value': selectValues && selectValues.length && !isFocused, 'select__label--focused': isFocused }">{{ text }}</label>

    <multiselect
        v-model="selectValues"
        :name="name"
        :placeholder="placeholder"
        :tag-placeholder="placeholder"
        select-label="select-label"
        :id="id"
        :object="true"
        :options="options"
        :label="label"
        :track-by="trackBy"
        open-direction="bottom"
        :multiple="multiple"
        :mode="multiple ? 'tags' : 'single'"
        :searchable="true"
        :loading="isLoading"
        :internal-search="false"
        :clear-on-select="false"
        :close-on-select="true"
        :options-limit="300"
        :max-height="100"
        :show-no-results="false"
        :hide-selected="true"
        @search-change="asyncFind"
        @select="onChange"
        @remove="onChange"
        @open="isFocused = true"
    >

    </multiselect>
    <div v-if="inputError" class="msg-error">{{inputError}}</div>
  </div>
</template>

<script>
import Ajax from '../helpers/ajax';
import Multiselect from '@vueform/multiselect'

export default {
  name: 'AutosuggestMulti',

  components: {
    Multiselect
  },

  props: {
    name: String,

    text: String,
    value: {},
    multiple: {
      type: Boolean,
      default: true
    },
    trackBy: {
      type: String,
      default: 'value'
    },
    placeholder: {
      type: String,
      default: 'Выбрать'
    },
    label: {
      type: String,
      default: 'label'
    },
    url: String
  },

  computed: {
    isEmpty() {
      return (!this.selectValues) || (!this.selectValues.length);
    }
  },

  watch: {
    value() {
      this.setValue(this.value)
    }
  },

  created() {
    this.asyncFind('');
  },

  data () {
    return {
      isLoading: false,
      id: `inp_${this.name.toLowerCase()}`,
      inputError: '',
      isFocused: false,
      options: [],
      selectValues: this.value,
    }
  },

  methods: {
    onChange() {
      this.$nextTick(() => {
        this.$emit('update:modelValue', this.selectValues)
        this.$emit('input', this.selectValues);
      });
    },

    async asyncFind(query) {
      let res = await Ajax.get(`${this.url}${this.url.indexOf('?') > -1 ? '&' : '?'}query=${encodeURIComponent(query)}`);
      this.isLoading = false;
      this.options = res;
    },

    clear () {
      this.selectValues = []
    },

    getValue() {
      return this.selectValues;
    },

    setValue(val) {

      if (val) {
        for (let i = 0; i < val.length; i++) {
          if (!val[i].label && !val[i].value) {
            val.splice(i, 1);
          }
        }
      }

      this.selectValues = val;
    },

    getError() {
      return this.inputError;
    },

    setError(val) {
      this.inputError = val;
    },

    clearError() {
      this.inputError = '';
    }
  }
}
</script>