<template>
    <div>
        <div class="filter-row-input">
            <input
                type="text"
                v-model="inputValue"
                v-bind:name="name"
                v-on:input="filterVal"
                v-on:paste="filterVal"
                v-on:keyup.enter="apply"
                v-bind:placeholder="placeholder ? placeholder : ' '"
            >
            <div class="clear-input" v-on:click="clear"></div>
        </div>
    </div>
</template>


<script>
    export default {
        name: 'InputFilter',

        props: {
            name: String,
            value: {},
            filterType: {
                type: String,
                default: 'eq'
            },
            placeholder: String,
            filterValue: RegExp
        },

        data () {
            return {
                inputValue: this.value || '',
                filterTypeValue: this.filterType,
            }
        },
      created() {
        if (!this.$root.$children) {
          this.$root.$children = [this];

        } else {
          this.$root.$children.push(this);
        }
      },
        methods: {
            clear() {
              this.inputValue = '';
              this.emitter.emit(`changeInputFilter_${this.name}`, '')
            },

            getValue() {
                return this.inputValue;
            },

            setValue(val) {
                this.inputValue = val;
            },

            getFilterType() {
                return this.filterTypeValue;
            },

            filterVal() {
                if (typeof this.filterValue === 'undefined') {
                    return;
                }

                this.inputValue = this.inputValue.replace(this.filterValue, '');
            },
          apply() {

            this.emitter.emit(`changeInputFilter_${this.name}`, '')
          }
        }
    }
</script>