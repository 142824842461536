<template>
    <td>
        <div class="filter-row-input">
            <select v-bind:id="id" v-model="inputValue" v-bind:name="name" v-on:change="setFilterStorage(name, inputValue)">
                <option value=""></option>
                <option v-for="option in options" v-bind:key="option[trackBy]" v-bind:value="option[trackBy]">
                    {{ option[label] }}
                </option>
            </select>
            <div class="clear-input"></div>
            <label v-bind:for="id" class="select-arrow"></label>
        </div>
    </td>
</template>


<script>
    export default {
        name: 'SelectFilter',

        props: {
            name: String,
            value: {},
            modelValue: {
              default: ''
            },
            filterType: {
                type: String,
                default: 'eq'
            },
            options: {
                type: Array,
                default: () => []
            },
            trackBy: {
                type: String,
                default: 'id'
            },
            label: {
                type: String,
                default: 'label'
            }
        },

        data () {
            return {
                id: `selectf_${Date.now()}_${Math.round(Math.random() * 99999)}`,
                inputValue: this.$cookies.get(this.name) || this.value || '',
                filterTypeValue: this.filterType,
            }
        },

      created() {
        if (!this.$root.$children) {
          this.$root.$children = [this];

        } else {
          this.$root.$children.push(this);
        }
      },

        methods: {
            clear() {
                this.inputValue = '';
            },

            getValue() {
                return this.inputValue;
            },

            setValue(val) {
                this.inputValue = val;
            },

            getFilterType() {
                return this.filterTypeValue;
            },

            setFilterStorage(key, value) {
                let routes = [
                    '/adm/flights',
                ];

                if (routes.indexOf(this.$route.path)) {
                    if (value) {
                        this.$cookies.set(this.$route.path + '.' + key, value);
                    } else {
                        this.$cookies.remove(this.$route.path + '.' + key);
                    }
                }

              this.emitter.emit(`changeSelectFilter_${key}`, '');
            }
        }
    }
</script>