<template>
  <div>
    <h1 class="tab-header">
      <span>
        Отчёты
      </span>
      <a v-on:click.prevent="newRollback = true">Новый отчёт</a>
    </h1>


    <Report ref="report" url="guide/rollback/report" :def-params="{order: {field: 't.rollback_id', order: 'desc'}, filters: {'t.account_id': 'eq|' + 4563}}" v-on:loading="reportLoading" v-on:done="reportLoaded">
      <div v-if="error">
        {{error}}
      </div>
      <div v-else>

        <div class="card-report">

          <div class="card-report_filter">
            <InputFilter name="flight_id" placeholder="Номер рейса" />
          </div>

          <div v-if="report">
            <div v-if="report.rows_count === 0">
              Не найдено.
            </div>

            <template v-else>
              <div class="item" v-on:click="getOrder(row.order_id)" v-for="(row, index) in report.rows" v-bind:key="index">
                <h3>
                  № {{row.rollback_id}} от {{convertDate(row.ts, 'DD.MM.YYYY HH:mm')}}
                </h3>

                <div>
                  Рейс: <b>№ {{row.flight_id}} - {{convertDate(row.schedule_date, 'DD.MM.YYYY HH:mm')}} - {{row.excursion_title}}</b>
                </div>

                <div>
                  Объект: <b>{{ row.object_title }}</b>
                </div>

                <div class="status" v-bind:class="`status_${row.order_status}`">
                  {{status[row.order_status]}}
                </div>

              </div>

            </template>
          </div>
        </div>


        <Pager />
      </div>
    </Report>

    <div class="window" v-if="newRollback">
      <div class="window-header">
        <h1 v-if="data.rollback && data.rollback.rollback_id">
          <i class="back" v-on:click="newRollback = false"></i>
          Отчёт № {{data.rollback.rollback_id}} от {{convertDate(data.rollback.ts, 'DD.MM.YYYY HH:mm')}}
        </h1>
        <h1 v-else>
          <i class="back" v-on:click="newRollback = false"></i>
          Новый отчёт
        </h1>
      </div>

      <div class="window-form">


        <div class="values">
          <div>
            <Select
                v-if="data.rollbackFlight"
                text="Рейс"
                name="order_place"
                v-model="newRollbackData.flight_id"
                :options="data.rollbackFlight"
                trackBy="flight_id"
                label="flight_title"
            />

            <Select
                v-if="newRollbackData.flight_id"
                text="Объект"
                name="order_place"
                v-model="newRollbackData.object_id"
                :options="data.rollbackFlight.find(i => i.flight_id === newRollbackData.flight_id).object_data"
                trackBy="object_id"
                label="object_title"
            />

            <template v-if="newRollbackData.object_id">

              <div v-for="(row, index) in data.rollbackFlight.find(i => i.flight_id === newRollbackData.flight_id).object_data.find(i => i.object_id === newRollbackData.object_id).object_price_data" v-bind:key="index">
                {{ row.object_price_title }}
                <div class="input-group">
                  <Input type="number" v-model="row.adult" icon2="чел." text="Взрослые" name="adult" />
                  <Input type="number" v-model="row.children_1" icon2="чел." text="Дети 1" name="children_1" />
                  <Input type="number" v-model="row.children_2" icon2="чел." text="Дети 2" name="children_2" />
                </div>
              </div>


              <div v-for="(row, index) in data.rollbackFlight.find(i => i.flight_id === newRollbackData.flight_id).object_data.find(i => i.object_id === newRollbackData.object_id).object_rollback_data" v-bind:key="index">
                {{ row.object_rollback_title }}
                <div class="input-group">
                  <Input type="number" v-model="row.amount" :text="row.object_rollback_type === 'count' ? 'Количество' : 'Сумма'"  name="amount[]" />
                </div>
              </div>

            </template>


            <button v-if="newRollbackData.object_id" class="btn btn-primary" v-on:click="save">Сохранить</button>

          </div>
        </div>



      </div>

    </div>
    <Nav />
  </div>
</template>

<script>
import mixins from '../../../helpers/mixins.js';
import Ajax from '../../../helpers/ajax';

import Report from '../../../components/report/Report';
import Pager from '../../../components/Pager';
import InputFilter from '../../../components/report/InputFilter';

import Nav from "@/pages/guide/nav";


import Select from "../../../components/Select";

import Input from "@/components/Input";

export default {
  mixins: [mixins],

  components: {
    Input,
    Report,
    Pager,
    Select,
    Nav,
    InputFilter,
  },

  created() {
    this.getRollbackFlight()
  },

  data() {
    return {
      newRollbackData: {
        flight_id: null,
        object_id: null,
        object_rollback_data:[]
      },
      newRollback: false,
      showOrder: null,
      data: {
        rollbackFLight: [

        ]
      },
      status: {
        'active': 'Активен',
        'paused': 'Заблокирован',
        'order': 'Оплачен',
        'cancel': 'Отменён',
        'cancel_success': 'Возврат',
        'waiting_for_capture': 'Оплачен, не подтверждён',
        'pending': 'Ожидает оплаты'
      },
      error: null,
      report: null
    }
  },

  methods: {

    async save() {
      let data = {
        flight_id: this.newRollbackData.flight_id,
        object_id: this.newRollbackData.object_id,
        object_rollback_data: {
          price: this.data.rollbackFlight.find(i => i.flight_id === this.newRollbackData.flight_id).object_data.find(i => i.object_id === this.newRollbackData.object_id).object_price_data,
          rollback: this.data.rollbackFlight.find(i => i.flight_id === this.newRollbackData.flight_id).object_data.find(i => i.object_id === this.newRollbackData.object_id).object_rollback_data
        }

      };


      let list = [];
      list.push(Ajax.post(`/guide/rollback/0`, data));

      let [userResult] = await Promise.all(list);

      if (userResult.error) {
        this.setError(userResult.error);
      }

      if (userResult.response) {
        if (this.$route.params.id === 'edit') {
          this.$toast.success({content:`Отчёт добавлен.`});
        } else {
          this.$toast.success({content:`Отчёт обновлён.`});
        }

        this.newRollback = false;
        this.$refs.report.reload()
      }



    },

    async getRollbackFlight() {

      let urls = {
        'rollbackFlight': `/guide/rollback/flight/`,
      };


      this.data = await Ajax.getBulk(urls);

    },

    reportLoading() {
      this.error = null;
    },

    reportLoaded(report) {
      if (report.response) {
        this.report = report.response;
        this.error = null;
      } else {
        this.report = null;
        this.error = 'Неизвестная ошибка.';
      }
    },



  }
}
</script>
