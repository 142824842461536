<template>
  <div>

    <h1>Касса</h1>
    <Report ref="report" url="collection/report" :def-params="{
      order: {
        field: 'collection_id', order: 'desc'
        },
        filters: {
          't.account_id': 'eq|' + cash.account_id,
          't.collection_date': 'bw|2024-01-01+00:00:00;' + convertDate(new Date(), 'YYYY-MM-DD+23:59:59')
        }}
       "
            v-on:loading="reportLoading"
            v-on:done="reportLoaded"
    >
      <div v-if="error">
        {{error}}
      </div>
      <div v-else>

        <div class="summary" v-if="report">
          <div>
            <template v-if="report.summary.seller_summary.sum_collection_d > 0">
              <b>{{formatRUB(report.summary.seller_summary.sum_current_cash_e)}}</b>
              <i>Основная</i><br>
              <b>{{formatRUB(report.summary.seller_summary.sum_current_cash_d)}}</b>
              <i>Допы</i><br>
            </template>
            <template v-else>
              <b v-on:click="showMore++">{{formatRUB(report.summary.seller_summary.sum_current_cash)}}</b>
              <i>Наличных в касса</i><br>
            </template>

            <button class="btn btn-primary mb14 mt14" v-on:click="sendMoney = !sendMoney;sendAward = false">Сдать кассу</button>
          </div>
          <div style="
              font-size: 88px;
              font-weight: 100;
              line-height: 76px;
              width: 24px;
          " v-if="showMore > 6">
            {
          </div>
          <div v-if="showMore > 6 || report.summary.seller_summary.sum_collection_d > 0">
            <template v-if="report.summary.seller_summary.sum_collection_d > 0">
              <b>{{formatRUB(report.summary.seller_summary.sum_current_cash)}}</b>
              <i>Касса сверки</i><br>
            </template>
           <template v-else>
             <b>{{formatRUB(report.summary.seller_summary.sum_current_cash_e)}}</b>
             <i>Основная</i><br>
             <b>{{formatRUB(report.summary.seller_summary.sum_current_cash_d)}}</b>
             <i>Допы</i>
           </template>

          </div>
        </div>

        <div v-if="sendMoney">
          <Input text="Сумма - основная" type="number" name="amount" icon2="₽" v-model="cash.amount" />
          <Input text="Сумма - допы" type="number" name="amount_d" icon2="₽" v-model="cash.amount_d" />
          <template v-if="Number.isInteger(cash.amount) || Number.isInteger(cash.amount_d)">
            <div class="mb14 mt14">
              Предъявите QR инкассатору.
            </div>
            <div class="mb14">
              <QRCodeVue3
                  :key="cash.amount+cash.amount_d"
                  :width="1200"
                  :height="1200"
                  :value="JSON.stringify({a: cash.account_id, e: cash.amount, d: cash.amount_d })"
                  :qrOptions="{ typeNumber: 0, mode: 'Byte', errorCorrectionLevel: 'H' }"
                  :imageOptions="{ hideBackgroundDots: true, imageSize: 0.8, margin: 10 }"
                  :dotsOptions="{
                    type: 'rounded',
                    color: '#000',
                    gradient: {
                      type: 'linear',
                      rotation: 0,
                      colorStops: [
                        { offset: 0, color: '#000' },
                        { offset: 1, color: '#000' },
                      ],
                    },
                  }"
                  :backgroundOptions="{ color: '#ffffff' }"
                  :cornersSquareOptions="{ type: 'dot', color: '#000000' }"
                  :cornersDotOptions="{ type: undefined, color: '#000000' }"
                  fileExt="svg"
                  :download="false"
                  myclass="partner-qur"
                  imgclass="img-qr"
                  downloadButton="btn btn-primary download"
                  :downloadOptions="{
                  name: `partner-qr`,
                  extension: 'svg'
              }"
              />

            </div>
            <button class="btn btn-primary mb14" v-on:click="cash.amount = null;cash.amount_d = null;$refs.report.reload()">Инкассатор принял деньги</button>
          </template>
        </div>

        <div class="summary" v-if="report">
          <div>
            <b>{{formatRUB(report.summary.seller_summary.sum_salary)}}</b>
            <i>Доступно ЗП</i><br>
            <button class="btn btn-primary mb14 mt14" v-on:click="sendAward = !sendAward;sendMoney = false">Получить ЗП</button>
          </div>
        </div>


        <template v-if="sendAward">
          <p>
            Введите сумму которую хотите получить, возьмите сумму из кассы и нажмите кнопку <b>сохранить</b>.
          </p>
          <Input text="Сумма" type="number" name="collection_amount" :max="report.summary.seller_summary.sum_salary" icon2="₽" v-model="award.collection_amount" />
          <template v-if="award.collection_amount > 0">
            <button class="btn btn-primary mb14" :disabled="report.summary.seller_summary.sum_salary < award.collection_amount" v-on:click="saveAward">Сохранить</button>
          </template>

        </template>


        <h3>Итого за выбранный период</h3>

        <PeriodPickerFilter placeholder="Период" :allow-future="false" :rangePreset="true" name="t.collection_date" />

        <div class="summary" v-if="report" >
          <div>
            <b>{{formatRUB(report.summary.seller_summary.sum_cash)}}</b>
            <i>Наличный расчёт</i>
          </div>
          <div>
            <b>{{formatRUB(report.summary.seller_summary.sum_card)}}</b>
            <i>Оплата картой</i>
          </div>
          <div>
            <b>{{formatRUB(report.summary.seller_summary.sum_online)}}</b>
            <i>Оплата онлайн</i>
          </div>
        </div>
        <div class="summary" v-if="report">
          <div>
            <b>{{formatRUB(report.summary.seller_summary.sum_refund_cash)}}</b>
            <i>Возвраты наличных</i>
          </div>
          <div>
            <b>{{formatRUB(report.summary.seller_summary.sum_refund_other)}}</b>
            <i>Остальные возвраты</i>
          </div>
          <div>
            <b>{{formatRUB(report.summary.seller_summary.sum_total)}}</b>
            <i>Итого продаж</i>
          </div>
        </div>
        <div class="summary" v-if="report" style="flex-wrap: wrap">

          <div>
            <b>{{formatRUB(report.summary.seller_summary.sum_only_collection)}}</b>
            <i>Инкассация</i>
          </div>
          <div>
            <b>{{formatRUB(report.summary.seller_summary.sum_collection_award)}}</b>
            <i>Получено ЗП</i>
          </div>
        </div>
        <h3>История изменений</h3>

        <div v-if="report" class="card-report">
          <div v-if="report.rows_count === 0">
            Не найдено.
          </div>

          <template v-else>
            <div
                class="item"
                v-for="(row, index) in report.rows"
                v-bind:key="index"
            >
              <h3 class="d-flex flex-space_between">
                {{row.collector_id ? collectionType[row.collection_type]  : 'ЗП'}} -
                {{convertDate(row.collection_date)}}
                <span>
                  -{{formatRUB(row.collection_amount)}}
                </span>
              </h3>

              <div v-if="row.collector_id">
                Забрал: <b>{{row.collector_title}}</b>
              </div>

            </div>

          </template>
        </div>

        <Pager />
      </div>
    </Report>


    <Nav />
  </div>
</template>


<script>
import mixins from '../../../helpers/mixins.js';
import Input from "@/components/Input";

import Nav from "@/pages/cashier/nav";
import User from "@/user";
import Report from "@/components/report/Report";
import Ajax from "@/helpers/ajax";
import PeriodPickerFilter from "@/components/report/PeriodPickerFilter";
import QRCodeVue3 from "qrcode-vue3";

export default {
  mixins: [mixins],

  components: {
    Input,
    Report,
    PeriodPickerFilter,
    QRCodeVue3,
    Nav
  },

  created() {

  },

  data() {
    return {
      showMore: 0,
      sendAward: false,
      error: null,
      report: null,
      availableBalance: 0,
      sendMoney: false,
      collectionType: {
        basic: 'Основная',
        other: 'Допы'
      },
      cash: {
        amount: null,
        amount_d: null,
        account_id: User.currentUser.account.account_id || 0
      },
      award: {
        award: true,
        collection_amount: null,
        account_id: User.currentUser.account.account_id || 0
      }

    }
  },

  methods: {

    async saveAward() {

      let list = []

      list.push(Ajax.post(`/collection/save/0`, this.award));

      let [orderResult] = await Promise.all(list);

      if (orderResult.error) {
        this.$toast.error({content: `Ошибка сохранения`});
      } else {
        this.$toast.success({content: `Информация сохранена`});

        this.sendAward = false;
        this.award.amount = 0;
        this.$refs.report.reload()
      }

    },

    reportLoading() {
      this.error = null;
    },

    reportLoaded(report) {
      if (report.response) {
        this.report = report.response;
        this.error = null;
      } else {
        this.report = null;
        this.error = 'Неизвестная ошибка.';
      }
    },

  }
}
</script>

<style>
.img-qr {
  width: 100%;
}
</style>