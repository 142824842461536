   <template>
        <div>
          <h1>Заявки на доставку</h1>

          <router-link to="/cashier/delivery/new" class="btn btn-primary">
            Новая заявка
          </router-link>
          <div>



        <Report ref="report" url="delivery/report" :def-params="{order: {field: 'delivery_id', order: 'desc'}, filters: {account_id: 'eq|'+user.account_id}}" v-on:loading="reportLoading" v-on:done="reportLoaded">
        <Loading />
        <div v-if="error">
          {{error}}
        </div>
        <div v-else>
          <div class="card-report">
            <div v-if="report">
              <div v-if="report.rows_count === 0">
                Не найдено.
              </div>

              <template v-else>
                  <div class="item" v-for="(row, index) in report.rows" v-bind:key="index">
                    <h3>
                      № {{row.delivery_id}} от {{convertDate(row.delivery_create_date, 'DD.MM HH:mm')}}
                    </h3>

                    <div>
                      <b>{{row.point_title}}</b>
                    </div>
                    <div class="ptr-item" v-for="(item, i) in row.items" v-bind:key="i">
                      <div>
                        {{item.delivery_product_title}}
                      </div>
                      <div>
                        {{item.delivery_item_count}}шт.
                      </div>
                      <div>
                        {{statuses[item.delivery_item_status]}}
                      </div>
                    </div>

                    <div class="status" v-bind:class="`status_${row.delivery_status}`">
                      {{data.status[row.delivery_status]}}
                    </div>
                  </div>
              </template>
          </div>
          </div>

          <div style="display: none">
            <Pager />
          </div>
        </div>
      </Report>
          </div>
          <Nav />
        </div>
</template>


<script>
import mixins from '../../../helpers/mixins.js';
import Ajax from '../../../helpers/ajax';
import Loading from '../../../components/report/Loading';
import Report from '../../../components/report/Report';
import Pager from '../../../components/Pager';
import Nav from "@/pages/cashier/nav";
import User from '../../../user';


export default {
  mixins: [mixins],

  components: {
    Loading,
    Pager,
    Nav,
    Report
  },

  data() {
    return {
      user: User.currentUser.user || {},
      statuses: {
      new: 'Ожидает',
      done: 'Собран',
      },
      data: {
        status: {
          'new': 'Новая',
          'processed': 'В сборке',
          'delivery': 'В доставке',
          'done': 'Выполнен'
        },
      },
      loading: false,
      error: null,
      report: null
    }
  },

  methods: {


    getReport() {
      return this.$refs.report;
    },

    pageChanged(page, limit) {
      this.getReport().pageChanged(page, limit);
    },

    reportLoading() {
      this.error = null;

    },

    reportLoaded(report) {
      if (report && report.response) {
        this.report = report.response;
        this.error = null;
      }

    },

    async changeStatus(id, status) {

      if (status === 'deleted') {
        if (confirm("Вы подтверждаете удаление?")) {
          await Ajax.post(`/delivery/save/${id}`, {
            delivery_id: id,
            delivery_status: status
          });
        }
      } else {
        await Ajax.post(`/delivery/save/${id}`, {
          delivery_id: id,
          delivery_status: status
        });
      }

      this.getReport().reload();

    },

  }
}
</script>

<style>
.ptr-item {
  display: flex;
  justify-content: space-between;
  gap: 6px;
  border-bottom: 1px solid #cccc;
}
.ptr-item:last-child {
  border-bottom: none;
}
.ptr-item div {
  padding: 2px;
  border-right: 1px solid #cccc;
}
.ptr-item div:first-child {
  width: 100%;
}
.ptr-item div:last-child {
  border-right: none;
}
</style>