<template>
  <div>

    <h1>Мои продажи</h1>

    <div>
      <div class="tab">
        <router-link to="/cashier/finance/ex/">Экскурсии</router-link>
        <router-link to="/cashier/finance/ticket/" class="active">Билеты</router-link>
      </div>

    </div>

    <Report ref="report" url="sale/report" :def-params="{order: {field: 'sale_id', order: 'desc'}, filters: {sale_seller_id: 'eq|' + user.account_id}}" v-on:loading="reportLoading" v-on:done="reportLoaded">
      <div v-if="error">
        {{error}}
      </div>
      <div v-else>

        <div class="card-report">

          <div class="card-report_filter">
            <InputFilter name="order_id" placeholder="Номер продажи" />
            <InputFilter name="order_title" placeholder="Наименование" filter-type="like" />

            <div class="input-group">
              <PeriodPickerFilter placeholder="Дата мероприятия" :allow-future="true" name="sale_event_date" />
              <PeriodPickerFilter placeholder="Дата продажи" name="sale_date" />
            </div>
            <SelectFilter name="sale_status" :options="[['order', 'Оплачен'], ['waiting_for_capture', 'Оплачен, не подтверждён'], ['reserved', 'Бронь'], ['cancel', 'Отменён'], ['cancel_success', 'Возврат']]" track-by="0" label="1" />

          </div>

          <div v-if="report">
            <div v-if="report.rows_count === 0">
              Не найдено.
            </div>

            <template v-else>
              <div class="item" v-on:click="getOrder(row.sale_id)" v-for="(row, index) in report.rows" v-bind:key="index">
                <h3>
                  № {{row.sale_id}} от {{convertDate(row.sale_date, 'DD.MM.YYYY HH:mm')}}
                </h3>

                <div>
                  {{row.product_title}}
                </div>

                <div>
                  Дата мероприятия: <b>{{convertDate(row.order_date_ot, 'DD.MM.YYYY HH:mm')}}</b>
                </div>

                <div>
                  оплачено: <b>{{formatRUB(row.sale_price_total)}}</b>
                </div>

                <div class="status" v-bind:class="`status_${row.sale_status}`">
                  {{status[row.sale_status]}}
                </div>

              </div>

            </template>
          </div>
        </div>


        <Pager />
      </div>
    </Report>

    <div class="window" v-if="showOrder">
      <div class="window-header">
        <h1>
          <i class="back" v-on:click="showOrder = null"></i>
          Продажа № {{data.order.sale_id}} от {{convertDate(data.order.sale_create_date, 'DD.MM.YYYY HH:mm')}}
        </h1>
        <div class="status" v-bind:class="`status_${data.order.sale_status}`">
          {{status[data.order.sale_status]}}
        </div>
      </div>

      <div class="window-form">

        <div class="values">
          <div>
            Продукт
          </div>
          <div>
            {{data.order.product_data ? data.order.product_data[0].label : ''}}
          </div>
        </div>

        <div class="values">
          <div>
            Дата мероприятия
          </div>
          <div>
            {{convertDate(data.order.sale_event_date, 'DD.MM.YYYY HH:mm')}}
          </div>
        </div>


        <div class="values">
          <div>
            Стоимость
          </div>
          <div>
            {{ formatRUB(data.order.sale_price_total) }}
          </div>
        </div>


      </div>

    </div>

    <Nav />
  </div>
</template>

<script>
import mixins from '../../../../helpers/mixins.js';
import Ajax from '../../../../helpers/ajax';

import Report from '../../../../components/report/Report';
import Pager from '../../../../components/Pager';
import PeriodPickerFilter from '../../../../components/report/PeriodPickerFilter';
import InputFilter from '../../../../components/report/InputFilter';
import SelectFilter from '../../../../components/report/SelectFilter';


import moment from "moment";

import User from '../../../../user';

import Nav from "@/pages/cashier/nav";

export default {
  mixins: [mixins],

  components: {
    Report,
    Pager,
    PeriodPickerFilter,
    InputFilter,

    SelectFilter,
    Nav
  },

  created() {

  },

  data() {
    return {
      user: User.currentUser.user || {},
      showOrder: null,
      data: {
        order: {
          place_data_set: null
        }
      },
      status: {
        'active': 'Активен',
        'paused': 'Заблокирован',
        'order': 'Оплачен',
        'cancel': 'Отменён',
        'cancel_success': 'Возврат',
        'waiting_for_capture': 'Оплачен, не подтверждён',
        'pending': 'Ожидает оплаты'
      },
      error: null,
      report: null
    }
  },

  methods: {

    async saveOrder() {
      let data = {
        order_title: this.data.order.order_title,
        order_id: this.data.order.order_id,
        order_comment: this.data.order.order_comment,
        order_place: this.data.order.order_place,
        order_place_time: this.data.order.order_place_time,
      };

      let list = [];
      list.push(Ajax.post(`/sale/save/${this.data.order.order_id || '0'}`, data));

      let [userResult] = await Promise.all(list);

      if (userResult.error) {
        this.setError(userResult.error);
      }

      if (userResult.response) {
        if (this.$route.params.id === 'edit') {
          this.$toast.success({content:`Заказ №${this.data.order.order_id} добавлен.`});
        } else {
          this.$toast.success({content:`Заказ №${this.data.order.order_id} обновлён.`});
        }
      }
    },

    async getOrder(order_id) {

      let urls = {
        'order': `/sale/${order_id}`,
      };


      this.data = await Ajax.getBulk(urls);

      this.data.order.sale_event_date = moment(this.data.order.sale_event_date).format('YYYY-MM-DD HH:mm')

      this.showOrder = true;

    },

    reportLoading() {
      this.error = null;
    },

    reportLoaded(report) {
      if (report.response) {
        this.report = report.response;
        this.error = null;
      } else {
        this.report = null;
        this.error = 'Неизвестная ошибка.';
      }
    },


  }
}
</script>
