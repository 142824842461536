<template>
  <label className="input" v-bind:for="id">

    <span className="input-placeholder">{{ text }}</span>

    <input className="text-field__input" placeholder=" " type="text" ref="container" readonly v-model="dateValue" :name="id"
           :id="id" :class="{ 'text-field__input--value': dateValue }"/>
    <input type="hidden" ref="hidden" v-bind:name="name" v-model="inputValue" data-type="bw"
           className="js-filter-field"/>

  </label>
</template>


<script>
import $ from 'jquery';
import moment from 'moment';
// eslint-disable-next-line no-unused-vars
import daterangepicker from 'daterangepicker';


export default {
  name: 'PeriodPicker',

  props: {
    modelValue: {
      default: ''
    },
    name: String,
    text: String,
    addTime: String,
    showTime: String,
    value: {},
    singleDate: {
      default: true
    },
    hasMacro: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],

  watch: {
    inputValue() {
      this.inputValue = this.modelValue;
      this.$emit('update:modelValue', this.inputValue)
      var resultFormat = (this.boolAddTime) ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD',
          d = this.inputValue.split(';');

      this.dateValue = moment(d[0], resultFormat).format((this.boolAddTime) ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD');

    }
  },

  computed: {
    isEmpty() {
      return !this.dateValue;
    }
  },

  data() {
    return {
      dateValue: '',
      id: `periodpicker_${Date.now()}_${Math.round(Math.random() * 99999)}`,
      inputValue: this.modelValue,
      boolAddTime: (this.addTime) && (['1', 't', 'true', 'y', 'yes'].indexOf(this.addTime) > -1),
      macroDate: '',
    }
  },

  mounted() {
    const params = {
      autoApply: true,
      autoUpdateInput: false,
      locale: {
        format: "YYYY-MM-DD",
        cancelLabel: 'Очистить',
        applyLabel: 'Применить',
        firstDay: 1
      },
      "linkedCalendars": true,
      "showCustomRangeLabel": false,
      "showRangeLabel": false,
      "alwaysShowCalendars": true,
      "singleDatePicker": true,
      "timePicker": this.boolAddTime,
      "timePicker24Hour": this.boolAddTime,
    };

    if (this.inputValue) {

      var resultFormat = (this.boolAddTime) ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD',
          d = this.inputValue.split(';');

      params.date = moment(d[0], resultFormat).format((this.boolAddTime) ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD');

      this.dateValue = params.date;
    }

    $(this.$refs.container).daterangepicker(params);

    $(this.$refs.container).on('cancel.daterangepicker', () => {
      this.inputValue = '';
      this.dateValue = '';
      this.$emit('update:modelValue', this.inputValue)
      this.$emit('cancel');
    });

    $(this.$refs.container).on('apply.daterangepicker', (ev, picker) => {

      this.dateValue = picker.startDate.format((this.boolAddTime) ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD');

      if (this.boolAddTime) {
        this.inputValue = picker.startDate.format('YYYY-MM-DD HH:mm:ss');
      } else {
        this.inputValue = picker.startDate.format('YYYY-MM-DD');
      }

      this.$emit('update:modelValue', this.inputValue)

    });
  },

  methods: {
    clear() {
      this.inputValue = '';
      this.dateValue = '';
      this.macroDate = '';

      let now = new Date();

      $(this.$refs.container).data('daterangepicker').setStartDate(now);
    },

    getValue() {
      return this.hasMacro && this.macroDate ? this.macroDate : this.inputValue;
    },

    setValue(val) {
      let date;

      let resultFormat = (this.boolAddTime) ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD';
      let d = val.split(';');

      date = moment(d[0], resultFormat).format((this.boolAddTime) ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD');
      this.inputValue = val;

      this.dateValue = date;

      $(this.$refs.container).data('daterangepicker').setStartDate(date);
    },
  },

}
</script>
