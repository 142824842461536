<template>
  <div>
    <h1 class="tab-header">
      <router-link to="/cashier/ex">Экскурсии</router-link>
      <span>
        Допы
      </span>
    </h1>

    <div class="ex-search">
      <Input type="text" name="search" v-model="search" placeholder="Поиск экскурсии" />
    </div>

    <div class="dates">
      <div
          class="dates-item"
          v-for="(date, index) in dates"
          v-bind:key="index"
          v-on:click="
            activeDay = date.day;
            activeDate = date.clearDate
          "
          :class="{
            'weekends': date.weekends,
            'active': activeDay === date.day
          }"
      >
        <div class="day">
          {{date.day}}
        </div>
        <div class="day-title">
          {{date.dayTitle}}
        </div>
      </div>
    </div>

    <Report v-if="profile > 0" key="report" ref="report" url="/flights/report"
            :def-params="{
                order: {
                  field: 'excursion_id',
                  order: 'desc'
                  },
                  filters: {
                    subdivision_id: 'eq|' + profile,
                    schedule_date: 'bw|' + filterDate,
                    excursion_publication: 'eq|disabled'
                }
              }"
            v-on:loading="reportLoading" v-on:done="reportLoaded">
      <Loading />
      <div v-if="error">
        {{error}}
      </div>
      <div v-else class="full-width-content table-wrapper">
        <template v-if="report">
          <div class="ex-cards">
            <template v-for="(row, index) in report.rows">
              <div class="item_wrap" v-bind:key="index" v-if="
                  (row.dates && Object.keys(row.dates).find(i => i.indexOf(activeDate) !== -1)) &&
                  (search && search.length ? row.excursion_title.toLowerCase().indexOf(search.toLowerCase()) > -1 : true)
                ">
                <button  style="z-index: 3;cursor:pointer;" class="btn btn-primary" v-on:click="loadMedia(row.excursion_id)">+</button>
                <div
                    class="item"
                    :style="{'background-image': 'url('+domain+'/img/excursion/list/' + (row.excursion_images ? row.excursion_images[0] : 'n.webp)')}"
                >
                  <div
                      style="
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        z-index: 2;
                    "
                      v-on:click="
                    activeEx = row;
                    createCalendar();
                    activeTime = convertDate(Object.entries(row.dates).find(i => i[0].indexOf(activeDate) > -1)[0], 'HH:mm');
                    activeFlight = Object.entries(row.dates).find(i => i[0].indexOf(activeDate) > -1)[1].flight_id;
                    tr = new Date().getTime();
                    setOrderPlaceTime()
                    newTickets = null;
                    setPlaceTitle()
                  " ></div>

                  <div class="item-wrap">
                    <div>
                      <div class="title" style="font-size: 18px">
                        {{row.excursion_title}}
                      </div>

                      <div class="duration">
                        {{row.excursion_duration ? prepareDuration(row.excursion_duration) : '-'}}
                      </div>
                      <div class="times">
                        <template v-for="(time, index) in Object.keys(row.dates)" >
                          <div
                              class="times-item"
                              style="position: relative;z-index: 3;cursor:pointer;"
                              v-bind:key="index"
                              v-if="time.indexOf(activeDate) > -1"
                              v-on:click="
                              activeEx = row;
                              createCalendar();
                              activeTime = convertDate(time, 'HH:mm');
                              activeFlight = row.dates[time].flight_id;
                              tr = new Date().getTime();
                              setOrderPlaceTime()
                              newTickets = null;
                              setPlaceTitle()
                  "
                          >
                            {{convertDate(time, 'HH:mm')}}
                            <span class="stop-limit" v-if="row.dates[time].limit_stop === 'Y'">
                              <template v-if="row.dates[time].limit <= row.dates[time].count">
                                - мест нет
                              </template>
                              <template v-else>
                                - свободно {{row.dates[time].limit - row.dates[time].count}} {{declination(row.dates[time].limit - row.dates[time].count, ['место', 'места', 'мест'])}}
                              </template>
                            </span>

                          </div>
                        </template>
                      </div>
                    </div>
                    <div class="prices">
                      <div class="prices-item">
                        {{formatRUB(row.excursion_price_adult)}}
                        <div>
                          взрослый
                        </div>
                      </div>
                      <div class="prices-item">
                        {{formatRUB(row.excursion_price_children_free)}}
                        <div>
                          дети ({{row.excursion_age_children_free_s}}-{{row.excursion_age_children_free_e+1}})
                        </div>
                      </div>
                      <div class="prices-item">
                        {{formatRUB(row.excursion_price_children)}}
                        <div>
                          дети ({{row.excursion_age_children_s+1}}-{{row.excursion_age_children_e}})
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>

            <div class="not-fount">
              Упс, экскурсий не нашлось:(
            </div>

          </div>
        </template>
      </div>

      <Pager />
    </Report>


    <div class="window" v-if="activeEx">
      <div class="window-header">
        <h1>
          <i class="back" v-on:click="back()"></i>
          Новый заказ
        </h1>
      </div>
      <div class="sum">
        <div>
          <b>
            <template v-if="order.allSumPrepay === order.allSum">
              {{ formatRUB(
                Math.ceil(getSum(
                    order.allSum
                ))
            )}}
            </template>
            <template v-if="order.allSumPrepay < order.allSum">
              {{ formatRUB(
                Math.ceil(getSalePrepay(
                    order.allSumPrepay,
                    order.allSum
                ))
            )}}
            </template>
          </b>
          <i>к оплате</i>
        </div>
        <div>
          <b>
            {{formatRUB(
              Math.ceil(getSum(
                  order.allSum
              ))
          )}}
          </b>
          <i>итого</i>
        </div>
        <div>
          <b v-if="seatsMessage" style="color:red">
            {{ seatsMessage }}
          </b>
          <b v-else>{{ newTickets || '-' }}</b>
          <i>места</i>
        </div>
      </div>


      <div class="window-form">

        <div class="steps" :data-step="orderStep" :data-title="orderStepTitle[orderStep]">
          <i></i>
          <i></i>
          <i></i>
          <i></i>
        </div>

        <div v-show="orderStep === 1">
          <div class="target_click" v-on:click="activeEx = false">
            <Input name="excursion_title" text="Экскурсия" required="true" v-model="activeEx.excursion_title" disabled="disabled" />
          </div>

          <div v-on:click="showDate = true">
            <Input name="ex_time" text="Дата и время отправления" required="true" v-model="activeDateTime" readonly="true" />

            <div class="msg-error" style="margin-bottom: 20px" v-if="saleDisable">
              Рейс уехал, выберите другое время или дату!
            </div>
          </div>

          <div class="target_click" v-on:click="showPlace = true">
            <Input name="ex_place" text="Количество человек" required="true" v-model="order.placeTitle" disabled="disabled" />
          </div>

          <div class="input-group">
            <Select
                text="Место посадки"
                name="order_place"
                v-model="order.order_place"
                :options="activeEx.place_data"
                trackBy="place_id"
                label="place_title"
            />

          </div>

          <div v-if="order.order_place_time">
            <Input disabled="disabled" text="Время посадки" name="place_time" v-model="order.order_place_time" />
          </div>
          <div class="msg-error" v-if="!order.order_place">
            Укажите место посадки
          </div>

        </div>

        <div v-show="orderStep === 2">
          <div class="input-group" v-for="index in orderPhoneCount" v-bind:key="index">
            <Input type="tel" name="order_phone" v-model="order.order_phone" text="Телефон" mask="+#(###)###-##-##" required="true" />
          </div>

          <Input name="order_client_title" text="ФИО" required="true" v-model="order.order_client_title" />

          <div class="msg-error mb14" v-if="activeEx.excursion_disable_promo">
            Скидки на этот рейс отключены.
          </div>

          <div class="input-group" v-if="!activeEx.excursion_disable_promo">
            <Input name="order_promo" text="Промокод" v-model="order.order_promo" />
            <div class="d-flex flex-align-center count-btn">
              <button class="btn btn-primary mb-20" :disabled="!order.order_promo || order.order_promo.length < 3" v-on:click="checkPromo">
                Активировать
              </button>
            </div>
          </div>

          <div class="msg-error mb14" v-if="promoData && !promoData.length">
            Промокод не найден
          </div>

          <div class="msg-success mb14" v-if="promoData && promoData.length && promoData[0].promo_title">
            Промокод активирован
          </div>

          <div class="tags" v-if="!activeEx.excursion_disable_promo">
            <i v-for="( t, i ) in promo" v-bind:key="i" v-on:click="order.order_promo = t.promo_code;checkPromo()">{{ t.promo_title }}</i>
          </div>

        </div>

        <div v-show="orderStep === 3">

          <div style="display: none">
            <div class="input">
              <label class="input-placeholder">Экскурсия</label>
              <div>
                {{activeEx.excursion_title}}
              </div>
            </div>


          </div>

          <div class="input">
            <Select
                text="Способ оплаты"
                class="mb-0"
                id="pay_type"
                name="pay_type"
                required="true"
                :options="this.isAndroid() ? [['cash', 'Наличными кассиру'], ['card', 'Картой или QR'], ['online', 'Онлайн']] : [['cash', 'Наличными кассиру'], ['online', 'Онлайн']]"
                track-by="0"
                label="1"
                v-model="order.pay_type"
            />
          </div>

          <div class="checkbox mb14" style="display: none">
            <label>
              <input
                  type="checkbox"
                  name="order_partial_pay"
                  value="true"
                  v-model="order.order_partial_pay"
              >
              <span>частичная оплата</span>
            </label>
          </div>

          <div v-if="order.order_partial_pay">
            <Input text="Сумма предоплаты" type="number" icon2="₽" v-model="order.order_partial_sum" name="ordedr_partial_pay" />
          </div>


          <Input name="order_comment" v-model="order.order_comment" text="Комментарий к заказу" value="" />

          <div class="tags">
            <i v-for="( t, i ) in tags" v-bind:key="i" v-on:click="order.order_comment = order.order_comment + t + ', '">{{ t }}</i>
          </div>

        </div>

      </div>
      <div class="form-submit">
        <button :disabled="saleDisable || seatsMessage || !order.order_place || (orderStep === 3 && !newTickets && preSaveShow) " class="btn btn-primary" v-on:click.prevent="orderStep === 3 ? preSaveShow = true : null;getSeats();orderStep = (orderStep < 3 ? orderStep + 1 : 3);">{{orderStep === 3 ? (order.pay_type === 'online' ? 'Подтвердить' : 'Продолжить') : 'Продолжить'}}</button>
        <a class="btn btn-secondary" v-on:click.prevent="orderStep = 1;back()">Отменить</a>
      </div>
    </div>


    <div class="window window-level" v-if="preSaveShow">
      <div class="window-header">
        <h1>Информация о заказе</h1>
        <a class="window-close" v-on:click="preSaveShow = false"></a>
      </div>
      <div class="window-form">
        <div class="order_label">
          Рейс
        </div>
        <div class="order_value">
          {{activeEx.excursion_title}}<br>
          {{convertDate(activeDate, 'DD.MM.YYYY')}}г. в {{ activeTime }}
        </div>
        <div class="order_label">
          Клиент
        </div>
        <div class="order_value">
          {{ order.order_phone }} - {{ order.order_client_title }}
        </div>
        <div class="order_label">
          Количество человек
        </div>
        <div class="order_value">
          {{ order.placeTitle }}
        </div>
        <div class="order_label">
          Точка посадки
        </div>
        <div class="order_value">
          {{ order.order_place ? data.places.rows.find(i => i.place_id === order.order_place).place_title : ''}}
        </div>
        <div class="order_label">
          Время посадки
        </div>
        <div class="order_value">
          {{convertDate(activeDate, 'DD.MM.YYYY')}}г. в {{ order.order_place_time }}
        </div>
        <div class="order_label">
          Места
        </div>
        <div class="order_value">
          <div :class="{mb14: !newTickets}">
            {{ newTickets || 'Места не получены!' }}
          </div>
          <div class="msg-error" v-if="!newTickets">
            Получите места и продолжите оформление заказа.
          </div>
        </div>

        <button v-if="!newTickets" :disabled="seatsLoading" class="btn btn-primary mb14" v-on:click="getSeats">
          <template v-if="seatsLoading">
            Подождите...
          </template>
          <template v-else>
            Получить места
          </template>
        </button>

        <div class="order_label">
          Комментарий к заказу
        </div>
        <div class="order_value">
          {{ order.order_comment || '-' }}
        </div>

        <div class="order_label">
          Способ оплаты
        </div>
        <div class="order_value">
          {{ {cash: 'Наличными кассиру', card: 'Картой или QR', online: 'Онлайн'}[order.pay_type] }}
        </div>

        <template v-if="order.order_partial_pay">
          <div class="order_label">
            Частичная оплата
          </div>
          <div class="order_value">
            Да
          </div>
        </template>

        <div class="sum mt14 mb-0">
          <div v-if="order.order_partial_pay">
            <b>
              {{ formatRUB(order.order_partial_sum) }}
            </b>
            <i>предоплата</i>
          </div>
          <div>
            <b>
              <template v-if="order.allSumPrepay === order.allSum">
                {{ formatRUB(
                  Math.ceil(getSum(
                          order.allSum
                      )
                  ) - parseInt(order.order_partial_pay ? order.order_partial_sum : 0))}}
              </template>
              <template v-if="order.allSumPrepay < order.allSum">
                {{ formatRUB(
                  Math.ceil(getSalePrepay(
                      order.allSumPrepay,
                      order.allSum
                  )) - parseInt(order.order_partial_pay ? order.order_partial_sum : 0)
              )}}
              </template>
            </b>
            <i>к оплате</i>
          </div>
          <div v-if="order.allSumPrepay < order.allSum">
            <b>
              {{ formatRUB(
                Math.ceil(getSum(
                    order.allSum
                ) - Math.ceil(getSalePrepay(
                    order.allSumPrepay,
                    order.allSum
                )))
            )}}
            </b>
            <i>гиду</i>
          </div>
          <div>
            <b>
              {{formatRUB(
                Math.ceil(getSum(
                    order.allSum
                ))
            )}}
            </b>
            <i>итого</i>
          </div>
        </div>

      </div>
      <div class="form-submit">
        <button :disabled="isSave || !newTickets" class="btn btn-primary" v-on:click.prevent="save">
          <template v-if="isSave">
            Подождите...
          </template>
          <template v-else>
            Всё верно
          </template>
        </button>
        <a class="btn btn-solid_white" v-on:click.prevent="preSaveShow = false">Отмена</a>
      </div>
    </div>

    <div class="window window-level" v-if="showDate">
      <div class="window-header">
        <h1>Дата и время</h1>
        <a class="window-close" v-on:click="showDate = false"></a>
      </div>
      <div class="window-form">
        <div class="schedule">
          <div class="item" v-for="(row, index) in schedule.calendar" v-bind:key="index">
            <div class="cal-tour">
              <div class="my_cal_box">
                <div class="title">
                  <b>{{row.mount}} {{row.year}}</b>
                </div>
                <div><b>Пн</b></div>
                <div><b>Вт</b></div>
                <div><b>Ср</b></div>
                <div><b>Чт</b></div>
                <div><b>Пт</b></div>
                <div><b>Сб</b></div>
                <div><b>Вс</b></div>

                <template v-for="(row, index) in row.days">

                  <template v-if="row.times">
                    <button
                        v-bind:key="index"
                        :disabled="!row.times.length"
                        class="time-on"
                        v-on:click="
                          times = row.times;
                          activeTime = false;
                          activeDate = row.date;
                          "
                        :class="{active: activeDate === row.date}"
                        :data-time="activeDate === row.date ? (times = row.times) : ''"
                    >
                      {{row.day || ''}}
                    </button>
                  </template>

                  <template v-else>
                    <div v-bind:key="index">
                      {{row.day || ''}}
                    </div>
                  </template>
                </template>
              </div>
            </div>
          </div>
        </div>

        <div class="times" v-if="times">
          <div
              v-for="(t, i) in times"
              v-bind:key="i"
              v-on:click="
                  activeTime = convertDate(t[0], 'HH:mm');
                  activeFlight = t[1].flight_id;
                  tr = new Date().getTime();
                  showDate = false
                  setOrderPlaceTime()
              "
              :class="{
                    active: activeTime === convertDate(t[0], 'HH:mm')
              }"
          >
            {{convertDate(t[0], 'HH:mm')}}
          </div>
        </div>

      </div>
    </div>

    <div class="window window-level" v-if="showPlace">
      <div class="window-header">
        <h1>Количество человек</h1>
      </div>
      <div class="msg-error" v-if="seatsMessage">
        {{ seatsMessage }}
      </div>
      <div class="msg-error" v-if="childrenMessage">
        Укажите возраст детей!
      </div>
      <div class="window-form">

        <div class="input-group">
          <Input type="number" name="order_adult" text="Взрослые" required="true" v-model.number.trim="order.order_adult" />

          <div class="d-flex flex-align-center count-btn">
            <a href="#" class="btn btn-middle btn-add" v-on:click.prevent="order.order_adult > 1 ? order.order_adult-- : false">-</a>
            <a href="#" class="btn btn-middle btn-add" v-on:click.prevent="order.order_adult++">+</a>
          </div>
        </div>


        <div class="input-group">
          <Input type="number" name="order_children" text="Дети" required="true" v-model.number.trim="order.order_children" />

          <div class="d-flex flex-align-center count-btn">
            <a href="#" class="btn btn-middle btn-add" v-on:click.prevent="order.order_children > 0 ? order.order_children-- : false">-</a>
            <a href="#" class="btn btn-middle btn-add" v-on:click.prevent="order.order_children++">+</a>
          </div>
        </div>

        <div class="kids_row input-group" v-for="n in order.order_children" v-bind:key="n">
          <div class="ar pt-10">
            <label>Возраст {{n}} ребёнка</label>
          </div>
          <div>
            <Select
                class="mb-0"
                id="order_age"
                name="order_age"
                required="true"
                :options="[[0, 'Нет'], [1, '1 год'], [2, '2 года'], [3, '3 года'], [4, '4 года'], [5, '5 лет'], [6, '6 лет'], [7, '7 лет'], [8, '8 лет'], [9, '9 лет'], [10, '10 лет'], [11, '11 лет']]"
                track-by="0"
                label="1"
                v-on:change="setChildrenCount"
                v-model="order.childrenValues[n-1]"
            />

            <div class="mb14" v-if="activeEx.excursion_price_children_free2_allow === 'true' && order.childrenValues[n-1] > 0 && order.childrenValues[n-1] <= activeEx.excursion_age_children_free2_e">

              <div class="checkbox">
                <label>
                  <input
                      type="checkbox"
                      name="age_place[]"
                      value="true"
                      v-on:change="setChildrenCount"
                      v-model="order.childrenValuesPlace[n-1]"
                  >
                  <span>Без места</span>
                </label>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="form-submit">
        <a class="btn btn-primary" v-on:click.prevent="savePeopleCount">Применить</a>
      </div>
    </div>

    <div class="window window-level" v-if="showMedia">
      <div class="window-header">
        <h1 class="mb-0">{{ showMedia.excursion_title }}</h1>
      </div>
      <div class="window-form">

        <div class="media-images">
          <template v-for="(img, key) in showMedia.excursion_images">
            <img v-bind:key="key" v-if="key > 0" :src="`${domain}/img/excursion/photo/preview/${img}`" />
          </template>
        </div>

        <h2>Цены на объектах посещения</h2>
        <p>
          Стоимость билетов и других услуг на объектах, посещаемых во время экскурсионного тура, которые оплачиваются на месте.
        </p>
        <div class="object" v-for="(row, index) in showMedia.object_price_data" v-bind:key="index">
          <div>
            <b>{{row.object_price_title}}</b>
            <div>
              {{showMedia.object_data_full.find(x => x.object_id === row.object_id).object_title }},
              {{showMedia.object_data_full.find(x => x.object_id === row.object_id).object_city }}
            </div>
          </div>
          <div class="prices" v-if="priceType">
            <div>
              {{formatRUB(row.object_price_adult)}}
              <div>
                {{priceType.find(x => x.value === row.object_price_adult_type).label }}
              </div>
            </div>
            <div>
              {{formatRUB(row.object_price_children_free)}}
              <div>
                {{priceType.find(x => x.value === row.object_price_children_free_type).label }}
              </div>
            </div>
            <div>
              {{formatRUB(row.object_price_children)}}
              <div>
                {{priceType.find(x => x.value === row.object_price_children_type).label }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-submit">
        <a class="btn btn-primary" v-on:click.prevent="showMedia = false">Закрыть</a>
      </div>
    </div>

    <Nav />
  </div>
</template>

<script>
import mixins from '../../../helpers/mixins.js';
import Ajax from '../../../helpers/ajax';
import moment from "moment";
import Input from "@/components/Input";
import Select from "@/components/Select";

import Report from "@/components/report/Report";
import Loading from '@/components/report/Loading';
import Pager from '@/components/Pager';


import Nav from "@/pages/cashier/nav";

import User from '../../../user';
import $ from "jquery";

export default {
  mixins: [mixins],

  components: {
    Nav,
    Input,
    Select,
    Report,
    Pager,
    Loading,
  },

  created() {
    let dateArray = [];
    let currentDate = new Date();
    let i = 0;

    this.loadPriceType();

    while (i < 30) {

      let d = new Date(currentDate)

      dateArray.push({
        date: d,
        clearDate: moment(d).format('YYYY-MM-DD'),
        day: moment(d).format('DD'),
        mount: moment(d).format('MM'),
        dayTitle: this.days[moment(d).isoWeekday()],
        weekends: moment(d).isoWeekday() > 5
      })

      currentDate = moment(currentDate).add(1, 'days');

      i++;
    }

    this.dates = dateArray;
    this.emitter.off("updateScheduleReport")
  },

  mounted() {
    this.loadPlace();
    this.emitter.off("updateScheduleReport")
    this.emitter.on("updateScheduleReport", () => {
      if (!this.tr) {
        this.getReport().reload()
      }
    })
  },

  watch: {
    activeDate() {
      this.activeDateTime = `${this.convertDate(this.activeDate, 'DD.MM.YYYY')}г. в ${this.activeTime}`;
      this.setPlaceTitle()
    },
    activeTime() {
      this.activeDateTime = `${this.convertDate(this.activeDate, 'DD.MM.YYYY')}г. в ${this.activeTime}`;
      this.setPlaceTitle()
    },

    'order.order_adult' () {
      this.setPlaceTitle()
    },
    'order.order_children' () {
      this.setPlaceTitle()
    },
    'order.childrenValues' () {
      this.setPlaceTitle()
    },
    'order.childrenValuesPlace' () {
      this.setPlaceTitle()
    },

    'order.order_place'() {
      this.setOrderPlaceTime()
    },

    async 'order.order_phone' (e) {
      let clearPhone = e.replace(/[^0-9]/g, '')

      if (clearPhone.length === 11) {
        let result = await Ajax.get(`/accounts/find-by-phone?type=all&phone=${clearPhone}`);
        if (result.length) {
          this.order.order_client_title = result[0].account_title
          this.order.account_id = result[0].id
        } else {
          this.order.account_id = null
        }
      }
    }
  },

  data() {
    return {
      isSave: false,
      profile: 0,
      promo: [],
      childrenMessage: false,
      preSaveShow: false,
      seatsLoading: false,
      filterDate: moment().format('YYYY-MM-DD 00:00:00') + ';' + moment().add(30, 'days').format('YYYY-MM-DD 23:59:59'),
      seatsMessage: null,
      tr: null,
      tags: [
        'Инвалид колясочник',
        'Только у окна',
        'Задний диван',
        'Поближе'
      ],
      saleDisable: false,
      newTickets: null,
      orderTickets: null,
      flightData: null,
      flight: null,
      priceType: [],
      showMedia: null,
      userData: User.currentUser,
      account_id: User.currentUser.account.account_id || 0,
      search: null,
      activeDateTime: '',
      dates: [],
      data: {
        exs: [],
        status: {
          'active': 'Активна',
          'paused': 'Заблокирована'
        },

        object: ['объект', 'объекта', 'объектов'],
        place: ['точка', 'точки', 'точек'],
        places: [],
      },
      days: ['', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс'],
      promoData: null,
      order: {
        order_partial_pay: false,
        order_partial_sum: 0,
        placeTitle: '1 взрослый',
        excursion_id: null,
        order_adult: 1,
        order_children: 0,
        childrenValues: [],
        childrenValuesPlace: [],
        childrenValuesCount1: 0,
        childrenValuesCount2: 0,
        childrenValuesCount3: 0,
        order_phone: '',
        order_client_title: '',
        account_id: null,
        order_comment: '',
        allSum: 0,
        allSumPrepay: 0,
        order_sum_sale: 0,
        pay_type: 'cash',
        sale_prepay_type: 'prepay',
        sale_amount: 0,
        sale_type: 'percent',
        order_place_time: '',
        order_place: null,
        transport_id: null
      },

      times: false,
      activeTime: false,
      activeFlight: null,
      activeDate: moment().format('YYYY-MM-DD'),
      schedule: {
        schedule_all: [],
        calendar: [],
        times: [
          [
            {
              h: '00',
              m: '00'
            }
          ],
          [
            {
              h: '00',
              m: '00'
            }
          ],
          [
            {
              h: '00',
              m: '00'
            }
          ],
          [
            {
              h: '00',
              m: '00'
            }
          ],
          [
            {
              h: '00',
              m: '00'
            }
          ],
          [
            {
              h: '00',
              m: '00'
            }
          ],
          [
            {
              h: '00',
              m: '00'
            }
          ]
        ],
        mount: [
          'Январь',
          'Февраль',
          'Март',
          'Апрель',
          'Май',
          'Июнь',
          'Июль',
          'Август',
          'Сентябрь',
          'Октябрь',
          'Ноябрь',
          'Декабрь'
        ],
        days: [
          'Понедельник',
          'Вторник',
          'Среда',
          'Четверг',
          'Пятница',
          'Суббота',
          'Воскресенье'
        ],
        schedule_types: [
          {
            id: '',
            title: 'Выбрать'
          },
          {
            id: 'all',
            title: 'Ежедневно'
          },
          {
            id: 'reply_day',
            title: 'Каждый X день недели'
          }
        ]
      },
      activeDay: moment().format('DD'),
      showPlace: false,
      activeEx: null,
      showDate: false,
      orderStep: 1,
      orderPhoneCount: 1,
      orderStepTitle: [
        '',
        'Общая информация',
        'Информация о клиенте',
        'Оплата и бронирование'
      ],
      moment: moment,
      error: null,
      report: null
    }
  },

  methods: {

    savePeopleCount() {
      if (this.order.order_children > 0 && this.order.childrenValues.filter(i => i > 0).length < this.order.order_children) {
        this.childrenMessage = true
      } else {
        this.childrenMessage = false;
        this.showPlace = false;
      }

    },

    setOrderPlaceTime() {

      if (this.activeEx && !this.order.order_place) {
        this.order.order_place = (this.activeEx.place_data && this.activeEx.place_data.length) ? this.activeEx.place_data[0].place_id : null
      }

      let order_date_ot = `${this.activeDate} ${this.activeTime}:00`;

      if (this.activeEx && this.activeEx.place_time && this.activeEx.place_time['t' + this.order.order_place]) {
        let i = this.activeEx.place_time['t' + this.order.order_place];
        let time = 0
        if (i.indexOf('-') > -1) {
          time = moment(order_date_ot).subtract(i.replace('-', ''), 'minutes').format('HH:mm')
        } else {
          time = moment(order_date_ot).add(i, 'minutes').format('HH:mm')
        }

        this.order.order_place_time = time
      }
    },

    async back() {
      if (this.orderStep === 1) {
        this.activeEx = null;
        this.order = {
          placeTitle: '1 взрослый',
          excursion_id: null,
          order_adult: 1,
          order_children: 0,
          childrenValues: [],
          childrenValuesPlace: [],
          childrenValuesCount1: 0,
          childrenValuesCount2: 0,
          childrenValuesCount3: 0,
          order_phone: '',
          order_client_title: '',
          account_id: null,
          order_comment: '',
          allSum: 0,
          allSumPrepay: 0,
          order_sum_sale: 0,
          pay_type: 'cash',
          sale_prepay_type: 'prepay',
          sale_amount: 0,
          sale_type: 'percent',
          order_place_time: '',
          order_place: null,
          transport_id: null
        }

        this.promoData = null

        if (this.tr) {
          await Ajax.get(`/flights/clear_seats/${this.tr}`);
          this.tr = null
        }
      } else {
        this.orderStep--
      }
    },

    async loadPriceType() {
      let price_type = await Ajax.get('/misc/price_type?query=');
      if (price_type) {
        this.priceType = price_type
      }

      let promo = await Ajax.get('/misc/find_account_promo');
      if (promo) {
        this.promo = promo
      }
      let profile = await Ajax.get('/profile/seller');

      if (profile.response && profile.response.schedule && profile.response.schedule.length) {
        let p = profile.response.schedule.find(i => i.schedule_date.indexOf(moment().format('YYYY-MM-DD')) > -1);
        if (p) {
          this.profile = p.subdivision_id
        } else {
          this.profile = this.userData.account.account_subdivision || 3
        }

      } else {
        this.profile = this.userData.account.account_subdivision || 3
      }

    },

    async loadMedia(excursion_id) {
      let result = await Ajax.get(`/excursion/${excursion_id}`);
      if (result.response) {
        this.showMedia = result.response;
      }
    },

    generatePassword() {
      let length = 8,
          charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
          retVal = "";

      for (let i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
      }
      return retVal;
    },

    async save() {
      this.clearError()
      this.isSave = true;

      if (!this.order.account_id) {
        let password = this.generatePassword()

        let data = {
          user_phone: this.order.order_phone !== '' ? this.order.order_phone.match(/[\d]/g).join('') : null,
          user_login: new Date().getTime() + '@vizit.tours',
          user_password: password,
          confirm_password: password,
          account_title: this.order.order_client_title,
          account_status: 'active'
        };

        let list = [];
        list.push(Ajax.post(`/account/save/0`, data));

        let [userResult] = await Promise.all(list);

        if (userResult.error) {
          if (userResult.error.message) {
            this.$toast.error({content: userResult.error.message})
            this.preSaveShow = false;
            this.isSave = false;
          } else {
            this.$toast.error({content: 'Проверьте номер телефона и ФИО клиента'})
            this.preSaveShow = false;
            this.isSave = false;
            this.setError(userResult.error);

          }
        }
        if (userResult.response) {
          this.order.account_id = userResult.response.account_id
          this.save(false)
        }

        return false
      }

      let orderData = this.order
      orderData.order_client_phone = orderData.order_phone.match(/[\d]/g).join('')
      orderData.cartSum = Math.ceil(this.getSum(this.order.allSum))
      orderData.cartSumSale = this.order.order_sum_sale
      orderData.cartSumPrepay = this.order.allSumPrepay === this.order.allSum ? Math.ceil(this.getSum(this.order.allSum)) : Math.ceil(this.getSalePrepay(this.order.allSumPrepay, this.order.allSum))
      orderData.cartSumFull = Math.ceil(this.getSum(this.order.allSum))
      orderData.excursion_id = this.activeEx.excursion_id
      orderData.excursion_title = this.activeEx.excursion_title
      orderData.excursion_date = this.activeDate + ' ' + this.activeTime + ':00'
      orderData.flight_id = this.activeFlight
      orderData.order_seller = this.account_id
      orderData.transaction_id = this.tr
      orderData.order_tickets = this.newTickets
      orderData.order_status = (this.order.pay_type === 'cash' ? 'order' : 'pending')
      orderData.order_pay_type = this.order.pay_type

      let list = [];
      list.push(Ajax.post(`/order/save_staff/0`, orderData));

      let [orderResult] = await Promise.all(list);

      if (orderResult.error) {
        this.$toast.error({content: `Ошибка сохранения`});
        this.preSaveShow = false;
        this.isSave = false;
        this.setError(orderResult.error);
      }

      if (orderResult.response) {

        if (orderResult.response.order_pay_type === 'card' && this.isAndroid()) {
          this.getPayCard(orderResult.response.order_pay_sum, orderResult.response.order_id)
        }

        this.activeEx = false;
        this.activeFlight = null;
        this.orderStep = 1
        this.orderPhoneCount = 1
        this.newTickets = null

        this.order = {
          placeTitle: '1 взрослый',
          excursion_id: null,
          order_adult: 1,
          order_children: 0,
          childrenValues: [],
          childrenValuesPlace: [],
          childrenValuesCount1: 0,
          childrenValuesCount2: 0,
          childrenValuesCount3: 0,
          order_phone: '',
          order_client_title: '',
          account_id: null,
          order_comment: '',
          allSum: 0,
          allSumPrepay: 0,
          order_sum_sale: 0,
          pay_type: 'cash',
          sale_prepay_type: 'prepay',
          sale_amount: 0,
          sale_type: 'percent',
          order_place_time: '',
          order_place: null,
          transport_id: null
        }

        this.promoData = null

        this.$toast.success({content:`Заказ успешно создан`});
        this.preSaveShow = false;
        this.isSave = false
        this.tr = null;



      }
    },

    async checkPromo() {
      let list = [];
      list.push(Ajax.get('/promo?query=' + this.order.order_promo + '&ex=' + this.activeEx.excursion_id));

      let [promoResult] = await Promise.all(list);

      if (promoResult) {
        this.promoData = promoResult;
        this.updateOrderPrices()
      }
    },

    updateOrderPrices(){
      if (this.activeEx && this.order) {
        this.order.allSum = (
            (this.activeEx.excursion_price_adult * this.order.order_adult) +
            (this.activeEx.excursion_price_children_free * this.order.childrenValuesCount1) +
            (this.activeEx.excursion_price_children * this.order.childrenValuesCount2) +
            (this.activeEx.excursion_price_children_free2 * this.order.childrenValuesCount3)
        )
        if (this.activeEx.excursion_prepay_allow === 'true') {
          this.order.allSumPrepay = (
              (this.activeEx.excursion_prepay_adult * this.order.order_adult) +
              (this.activeEx.excursion_prepay_children_free * this.order.childrenValuesCount1) +
              (this.activeEx.excursion_prepay_children * this.order.childrenValuesCount2) +
              (this.activeEx.excursion_prepay_children_free2 * this.order.childrenValuesCount3)
          )
        } else {
          this.order.allSumPrepay = (
              (this.activeEx.excursion_price_adult * this.order.order_adult) +
              (this.activeEx.excursion_price_children_free * this.order.childrenValuesCount1) +
              (this.activeEx.excursion_price_children * this.order.childrenValuesCount2) +
              (this.activeEx.excursion_price_children_free2 * this.order.childrenValuesCount3)
          )
        }
        this.order.order_sum_sale = Math.floor(this.getSaleSum(this.order.allSum))
      }
    },

    async getSeats() {
      this.seatsMessage = null
      this.seatsLoading = true
      let flight_id = this.activeFlight
      let count =
          this.order.order_adult +
          this.order.childrenValuesCount1 +
          this.order.childrenValuesCount2 +
          this.order.childrenValuesCount3 - (this.order.childrenValuesPlace.filter(x => x === true).length)

      if (!this.tr) {
        this.tr = new Date().getTime()
      }

      if (flight_id && this.tr) {
        let seats = await Ajax.get(`/orders/seats/?fl=${flight_id}&c=${count}&tr=${this.tr}&socket=${this.$socket.id}`);
        if (seats.response) {
          if (seats.response.error) {
            this.seatsMessage = seats.response.error
            return;
          }

          this.newTickets = seats.response.seats;
          this.order.transport_id = seats.response.transport_id || null;

          this.seatsLoading = false
        }
      } else {
        this.seatsLoading = false
      }


    },

    setChildrenCount() {
      this.order.childrenValuesCount1 = 0;
      this.order.childrenValuesCount2 = 0;
      this.order.childrenValuesCount3 = 0;

      if (this.order.childrenValues) {
        this.order.childrenValues.forEach((e, k) => {
          if (e <= this.activeEx.excursion_age_children_free_e && k < this.order.order_children) {
            if (this.order.childrenValuesPlace[k] && !!this.order.childrenValuesPlace[k]) {
              this.order.childrenValuesCount3++
            } else {
              this.order.childrenValuesCount1++
            }
          }
        });
        this.order.childrenValues.forEach((e, k) => {
          if (e > this.activeEx.excursion_age_children_s && k < this.order.order_children) {
            this.order.childrenValuesCount2++
          }
        });
      }
      this.updateOrderPrices()
    },

    getSale(sum, prepay = 0) {

      if (this.activeEx.excursion_sale_amount > 0) {
        if (this.activeEx.excursion_sale_type === 'percent') {
          if (this.activeEx.excursion_sale_prepay_type === 'all') {
            return sum - (sum / 100 * this.activeEx.excursion_sale_amount)
          }
          if (this.activeEx.excursion_sale_prepay_type === 'prepay') {
            return sum - (sum / 100 * this.activeEx.excursion_sale_amount)
          }
          if (this.activeEx.excursion_sale_prepay_type === 'other') {
            return sum - ((sum - prepay) / 100 * this.activeEx.excursion_sale_amount)
          }
        } else {
          return sum - this.activeEx.excursion_sale_amount
        }
      } else {
        return sum
      }
    },

    getSaleSum(sum, prepay = 0) {

      if (this.order.sale_amount > 0) {
        this.promoData = [{
          promo_sale: this.order.sale_amount,
          promo_summed: 'off',
          promo_type: this.order.sale_type,
          promo_type2: this.order.sale_prepay_type
        }]
      }

      if (this.activeEx) {
        if (this.promoData && this.promoData.length) {
          if (this.promoData[0].promo_type2 === 2 && this.promoData[0].promo_summed === 'off') {
            return this.order.allSumPrepay - this.getSalePrepay(this.order.allSumPrepay)
          }
          if (this.promoData[0].promo_type === 'percent') {
            if (this.promoData[0].promo_summed === 'on') {
              // add sale
              if (this.activeEx.excursion_sale_amount > 0) {
                if (this.activeEx.excursion_sale_type === 'percent') {
                  if (this.activeEx.excursion_sale_prepay_type === 'all') {
                    return sum / 100 * (this.activeEx.excursion_sale_amount + this.promoData[0].promo_sale)
                  }
                  if (this.activeEx.excursion_sale_prepay_type === 'prepay') {
                    return sum / 100 * (this.activeEx.excursion_sale_amount + this.promoData[0].promo_sale)
                  }
                  if (this.activeEx.excursion_sale_prepay_type === 'other') {
                    return ((sum - prepay) / 100 * (this.activeEx.excursion_sale_amount + this.promoData[0].promo_sale))
                  }
                } else {
                  return (sum / 100 * this.promoData[0].promo_sale) + this.activeEx.excursion_sale_amount
                }
              } else {
                sum = sum / 100 * this.promoData[0].promo_sale
              }
            } else {
              sum = sum / 100 * this.promoData[0].promo_sale
            }
          } else {
            if (this.promoData[0].promo_summed === 'on') {
              // add sale
              if (this.activeEx.excursion_sale_amount > 0) {
                if (this.activeEx.excursion_sale_type === 'percent') {
                  if (this.activeEx.excursion_sale_prepay_type === 'all') {
                    return sum / 100 * (this.activeEx.excursion_sale_amount) + this.promoData[0].promo_sale * (this.order.order_adult + this.order.order_children)
                  }
                  if (this.activeEx.excursion_sale_prepay_type === 'prepay') {
                    return sum / 100 * (this.activeEx.excursion_sale_amount) + this.promoData[0].promo_sale * (this.order.order_adult + this.order.order_children)
                  }
                  if (this.activeEx.excursion_sale_prepay_type === 'other') {
                    return ((sum - prepay) / 100 * (this.activeEx.excursion_sale_amount)) + this.promoData[0].promo_sale * (this.order.order_adult + this.order.order_children)
                  }
                } else {
                  return (this.promoData[0].promo_sale * (this.order.order_adult + this.order.order_children)) + this.activeEx.excursion_sale_amount
                }
              } else {
                sum = this.promoData[0].promo_sale * (this.order.order_adult + this.order.order_children)
              }

            } else {
              sum = this.promoData[0].promo_sale
            }
          }
        } else {

          if (this.activeEx.excursion_sale_amount > 0) {
            if (this.activeEx.excursion_sale_type === 'percent') {
              if (this.activeEx.excursion_sale_prepay_type === 'all') {
                return sum / 100 * this.activeEx.excursion_sale_amount
              }
              if (this.activeEx.excursion_sale_prepay_type === 'prepay') {
                return sum / 100 * this.activeEx.excursion_sale_amount
              }
              if (this.activeEx.excursion_sale_prepay_type === 'other') {
                return ((sum - prepay) / 100 * this.activeEx.excursion_sale_amount)
              }
            } else {
              return this.activeEx.excursion_sale_amount
            }
          } else {
            return 0
          }

        }
      }

      return sum
    },

    getSalePrepay(sum, price = 0) {

      let promoSale = 0;

      if (this.promoData && this.promoData.length) {
        if (this.promoData[0].promo_type2 === 2) {
          if (this.promoData[0].promo_type === 'percent') {
            if (this.promoData[0].promo_summed === 'on') {
              promoSale = (this.order.allSum / 100 * this.promoData[0].promo_sale)
            } else {
              return sum - (this.order.allSum / 100 * this.promoData[0].promo_sale)
            }
          } else {
            if (this.promoData[0].promo_summed === 'on') {
              promoSale = this.promoData[0].promo_sale * (this.order.order_adult + this.order.order_children)
            } else {
              return sum - this.promoData[0].promo_sale * (this.order.order_adult + this.order.order_children)
            }
          }
        }
      }

      if (this.activeEx.excursion_sale_amount > 0) {
        if (this.activeEx.excursion_sale_type === 'percent') {
          if (this.activeEx.excursion_sale_prepay_type === 'all') {
            return sum - promoSale
          }
          if (this.activeEx.excursion_sale_prepay_type === 'prepay') {
            return sum - (price / 100 * this.activeEx.excursion_sale_amount) - promoSale
          }
          if (this.activeEx.excursion_sale_prepay_type === 'other') {
            return sum - promoSale
          }
        } else {
          if (this.activeEx.excursion_sale_prepay_type === 'prepay') {
            return sum - this.activeEx.excursion_sale_amount - promoSale
          } else {
            return sum - promoSale
          }
        }
      } else {
        return sum - promoSale
      }
    },

    getSum(sum) {
      return Math.floor(this.order.allSum - this.getSaleSum(sum))
    },

    setPlaceTitle() {

      this.seatsMessage = null
      if (this.convertDate(this.activeDate, 'DD.MM.YYYY') === moment().format('DD.MM.YYYY')) {
        if (this.activeTime < moment().format('HH:mm')) {
          this.saleDisable = true
        } else {
          this.saleDisable = false
        }
      } else {
        this.saleDisable = false
      }

      this.order.placeTitle = `${this.order.order_adult} ${this.declination(this.order.order_adult, ['взрослый', 'взрослых', 'взрослых'])}`;

      if (this.order.order_children) {
        this.order.placeTitle = `${this.order.placeTitle}, ${this.order.order_children} ${this.declination(this.order.order_children, ['ребёнок', 'ребёнка', 'детей'])}`;
      }

      this.setChildrenCount()
    },

    createCalendar() {

      this.promoData = null

      this.schedule.calendar = [];


      let sm = parseInt(moment().format('M'));
      let em = parseInt(moment().add(3, 'months').format('M'));
      let y = parseInt(moment().format('YYYY'));

      for (let i = 0; i <= (em - sm); i++) {
        let mount = sm + i;
        let mountDate = moment().day(-1).month(mount - 1);

        let days = [];
        let countDays = mountDate.daysInMonth();
        let firstDay = mountDate.startOf('month').isoWeekday()-1

        for (let i = 0; i <= countDays + firstDay; i++) {

          let day = i - firstDay;

          let date = y + '-' + (mount < 10 ? '0' + mount : mount) + '-' + (day < 10 ? '0' + day : day);
          let timeData = Object.entries(this.activeEx.dates).filter(i => i[0].indexOf(date) > -1)

          days[i-1] = {
            day: day > 0 ? day : false,
            date: date,
            mount: mount,
            weekend: (mountDate.day(i).isoWeekday() > 5),
            times: timeData
          }
        }

        this.schedule.calendar[i] = {
          mount: this.schedule.mount[mount - 1],
          year: y,
          days: days
        }
      }

    },

    async loadPlace() {

    },

    getReport() {
      return this.$refs.report;
    },

    pageChanged(page, limit) {
      this.getReport().pageChanged(page, limit);
    },

    reportLoading() {
      this.error = null;
    },

    async reportLoaded(report) {
      if (report.response && report.response.rows) {

        let urls = {
          'places': `/settings/place/report?filters[place_status]=eq|active&order[field]=place_id&order[order]=desc&page=1&limit=500`,
        };

        let data = await Ajax.getBulk(urls);

        $.each(report.response.rows, function (k, v) {

          if (v.dates && v.dates.length) {
            let newDates = {}
            $.each(v.dates, (t, date) => {
              let keys = Object.keys(date[0]);

              if (keys && keys.length) {
                if (newDates[keys[0]]) {
                  newDates[keys[0]].tickets = newDates[keys[0]].tickets.concat(date[0][keys[0]].tickets)
                  newDates[keys[0]].count = newDates[keys[0]].tickets.length
                } else {
                  newDates[keys[0]] = date[0][keys[0]]
                  if (date[0][keys[0]].tickets[0] === 0) {
                    newDates[keys[0]].tickets = []
                  }
                  newDates[keys[0]].count = newDates[keys[0]].tickets ? newDates[keys[0]].tickets.length : 0
                }
              }

            })

            report.response.rows[k].dates = newDates;
            report.response.rows[k].place_data = []

            if (data.places.rows) {
              data.places.rows.map(i => {
                if (report.response.rows[k].excursion_place && report.response.rows[k].excursion_place.indexOf(i.place_id) > -1) {
                  report.response.rows[k].place_data.push(i)
                }
              })
            }
          }

        })

        this.data = data;
        this.report = report.response;

        this.error = null;
      } else {
        this.report = null;
        this.error = 'Неизвестная ошибка.';
      }

    },
  }
}
</script>

<style>
#inp_place_time {
  font-size: 32px;
}
</style>
