<template>
  <div>
    <h1 class="tab-header">
      <span>
        Архив рейсов
      </span>
    </h1>
    <div>

      <div class="card-report" v-if="data.flight && data.flight.length">
        <div class="item" v-for="(row, index) in data.flight" v-bind:key="index">
          <h3 v-on:click="loadFlightInfo(row.flight_id, false, row.sticky_flight)" class="card_header flex-space_between d-flex">
            Рейс № {{ row.flight_id }} {{row.sticky_flight.length ? '+'+row.sticky_flight.length : ''}} <span>{{ convertDate(row.schedule_date) }}</span>
          </h3>
          <b>
            {{ row.excursion_title }}
            <template v-if="row.sticky_flight.length">
              - {{row.cnt_total_seats_save }} {{declination(row.cnt_total_seats_save, ['мест', 'места', 'мест']) }}
            </template>
          </b><br>
          <div class="mb14" v-if="row.sticky_flight.length">
            <div v-for="(st, key) in row.sticky_flight" v-bind:key="key">
              <b>
                {{ st.excursion_title }} - {{st.cnt_total_seats }} {{declination(row.cnt_total_seats, ['мест', 'места', 'мест']) }}
              </b>
            </div>
          </div>
          <div>
            Транспорт: <b>{{ row.transport_title || 'ещё не назначен' }}</b>
          </div>
          <div class="total-count">
            <b :class="{green: row.cnt_checkin_seats === row.cnt_total_seats}">
              {{declination(row.cnt_checkin_people, ['Сел', 'Сели', 'Сели']) }} {{ row.cnt_checkin_people }} из {{ row.cnt_total_people }} чел. / {{ row.cnt_checkin_seats }} из {{ row.cnt_total_seats }} мест
            </b>
          </div>

          <template v-if="showFlight[row.flight_id] && showFlight[row.flight_id].show">

            <div class="place-list">
              <div class="p-item" v-for="(place, i) in showFlight[row.flight_id].response" v-bind:key="i">
                <div class="header" v-on:click="showFlight[row.flight_id].response[i].show = !showFlight[row.flight_id].response[i].show">
                  <div class="title">
                    {{ place.order_place_time }} | {{ place.place_title || '-' }}
                  </div>
                  <div class="time" v-if="place.order_data">
                    <i :class="{green: place.order_data && place.order_data.filter(i => i.order_checkin_adult > 0).length === place.order_data.length}">{{ place.order_data.filter(i => i.order_checkin_adult > 0).length }} / {{ place.order_data.length }}</i>
                    <i :class="{green: place.order_data && place.order_data.filter(i => i.order_checkin_adult > 0).length === place.order_data.length}">{{ place.cnt_adult + place.cnt_children_1 + place.cnt_children_2 + place.cnt_children_free }} чел.</i>
                  </div>
                </div>
                <div class="order-list" v-if="showFlight[row.flight_id].response[i].show">
                  <div
                      class="ol-item"
                      v-for="(order, order_index) in place.order_data"
                      v-bind:key="order_index"
                      :class="{
                          checkin: order.order_checkin_adult && (parseInt(order.order_checkin_children) + parseInt(order.order_checkin_adult)) > 0,
                          checkin_not_full: order.order_checkin_adult && order.order_checkin_adult > 0 && (
                              (parseInt(order.order_checkin_adult) + parseInt(order.order_checkin_children) + parseInt(order.order_checkin_children_free)) !==
                              (parseInt(order.order_adult) + parseInt(order.order_children_1) + parseInt(order.order_children_2)  + parseInt(order.order_children_free))
                          ),
                      }">
                    <div class="ol-name">
                      <span>
                        <i v-if="order.order_read_it"></i>
                        №{{ order.order_id }} - {{ order.order_client_title }}<br>
                        {{ order.order_title }}
                      </span>
                    </div>
                    <div class="ol-seats">
                      <div>
                        <b>{{ order.order_checkin_adult > 0 && parseInt(order.order_checkin_adult) !== parseInt(order.order_adult) ? `${order.order_checkin_adult} / ${order.order_adult}` : order.order_adult }}</b>
                        <i>Взрослые</i>
                      </div>
                      <div>
                        <b>{{ order.order_checkin_children > 0 && parseInt(order.order_checkin_children) !== (parseInt(order.order_children_1) + parseInt(order.order_children_2)) ? `${order.order_checkin_children} / ${parseInt(order.order_children_1) + parseInt(order.order_children_2)}` : parseInt(order.order_children_1) + parseInt(order.order_children_2) }}</b>
                        <i>Дети</i>
                      </div>
                      <div>
                        <b>{{ order.order_checkin_children_free > 0 && parseInt(order.order_checkin_children_free) !== parseInt(order.order_children_free) ? `${order.order_checkin_children_free} / ${order.order_children_free}` : order.order_children_free }}</b>
                        <i>Дети без места</i>
                      </div>
                      <div>
                        <b v-if="order.transport_place_title && order.transport_place_title.length">
                          {{
                            order.order_tickets ?
                                order.order_tickets.split(',').map(i => {
                                  return order.transport_place_title[parseInt(i)]
                                }).sort((a,b) => { return a === 'сл' || b === 'сл' ? 1 : parseInt(a) - parseInt(b)}).join(',') : ''
                          }}
                        </b>
                        <b v-else>
                          {{order.order_tickets.split(',').sort((a,b) => {return parseInt(a) - parseInt(b)}).join(',') }}
                        </b>
                        <i>Места</i>
                      </div>
                    </div>


                    <div class="ol-price">
                      <div v-if="parseInt(order.order_sum_pre) < parseInt(order.order_sum_all)">
                        <b>{{ formatRUB( parseInt(order.order_sum_all) -  parseInt(order.order_sum_pre) ) }}</b> - итого при посадке
                      </div>
                      <div v-else>
                        Заказ оплачен полностью
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>


    <Nav />

  </div>
</template>

<script>

import mixins from '../../../helpers/mixins.js';
import Nav from "@/pages/guide/nav";
import User from '../../../user';
import Ajax from "@/helpers/ajax";


export default {
  mixins: [mixins],

  components: {
    Nav
  },

  created() {
    this.loadFlight();
  },

  data() {
    return {
      arh: false,
      showFlight: [],
      account_id: User.currentUser.account.account_id || 0,
      data: {
        oldFlight: null
      },

      checkin: {
        new: false,
        order_id: null
      },

      activeFlight: null,

      checkinStep: 1,

      checkinStepTitle: [
        '',
        'Поиск билета',
        'Информация о билете',
      ],
      error: null,
      report: null
    }
  },

  methods: {

    async loadFlight() {
      this.data.landing = null;
      let urls = {
        'flight': `/guide/flight-archive`,
      };

      let res = await Ajax.getBulk(urls);

      let newFlight = []
      let oldFlight = [];

           if (res.flight) {

             res.flight.map((a, o) => {

               oldFlight.push({
                 flight_id: a.flight_id,
                 transport_id: a.transport_id
               })

               newFlight.push(a)
               newFlight[o].cnt_total_seats_save = a.cnt_total_seats
               newFlight[o].sticky_flight = []

               res.flight.map(b => {
                 if (a.schedule_date === b.schedule_date && a.flight_id !== b.flight_id && !newFlight.find(i => i.flight_id === b.flight_id) && !newFlight.find(i => i.sticky_flight.find(t => t.flight_id === b.flight_id))) {
                   newFlight[o].sticky_flight.push(b)
                   newFlight[o].cnt_checkin_people = newFlight[o].cnt_checkin_people + b.cnt_checkin_people
                   newFlight[o].cnt_total_people = newFlight[o].cnt_total_people + b.cnt_total_people

                   newFlight[o].cnt_checkin_seats = newFlight[o].cnt_checkin_seats + b.cnt_checkin_seats
                   newFlight[o].cnt_total_seats = newFlight[o].cnt_total_seats + b.cnt_total_seats
                 }
               })
             })

             newFlight.forEach(i => {
              if (i.sticky_flight.length) {
                i.sticky_flight.forEach(a => {
                  newFlight.forEach((b, k) => {
                    if (a.flight_id === b.flight_id) {
                      newFlight.splice(k, 1)
                    }
                  })
                })
              }
             })
           }


        res.oldFlight = oldFlight

        res.flight = newFlight;

      this.data = res
    },

    async loadFlightInfo(flight_id, reload = false, sticky_flight = []) {

      let flight_ids = flight_id;

      if (this.showFlight[flight_id] && !reload) {
        this.showFlight[flight_id].show = !this.showFlight[flight_id].show;
        return;
      }

      if (sticky_flight.length) {
        flight_ids = flight_ids + ',' + sticky_flight.map(i => i = i.flight_id).join(',')
      }

      let tmp = this.showFlight[flight_id]
      let res = await Ajax.get(`/guide/flight/${flight_ids}`);

      if (reload && tmp && tmp.response) {
        res.show = tmp && tmp.show ? tmp.show : false;
        tmp.response.forEach((i, k) => {
          res.response[k].show = tmp.response[k].show
        })

      } else {
        res.show = true;
      }

      this.showFlight[flight_id] = res
    },

    onLoaded() {
      console.log('onLoaded')
    },

  }
}
</script>
